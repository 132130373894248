import * as React from "react";
import { hot } from "react-hot-loader";

import "./../assets/scss/App.scss";
import "./../assets/scss/Fonts.scss";
import { Card } from "./Card";
import { LevelFailedModal } from "./LevelFailedModal";
import { LevelFinishedModal } from "./LevelFinishedModal";
import { Modal } from "./Modal";
import { Start } from "./Start";

//ffmpeg -i 0001-0091.avi -filter:v fps=fps=24 -lossless 1 -loop 0 -an -vsync 0 egg.webp

const bgm = require("./../assets/audio/spring.mp3");
const logo_ts = require("./../assets/img/logo-ts.svg");
const logo_ts2 = require("./../assets/img/logo-ts2.svg");
const fullscreen = require("./../assets/img/fullscreen.svg");
const final_screen = require("./../assets/img/final-screen.gif");
const egg = require("./../assets/img/egg.gif");
const egg_webp = require("./../assets/img/egg.webp");
const egg_apng = require("./../assets/img/egg.apng");
const rabbit = require("./../assets/img/hase.svg");
const zwischenscreen = require("./../assets/img/zwischenscreen.jpg");
const stoerer = require("./../assets/img/stoerer-rabatt.svg");
const beat_nico = require("./../assets/audio/beat-nico.mp3");
const congrats = require("./../assets/audio/congrats.wav");
const fanfare = require("./../assets/audio/fanfare.mp3");
const win = require("./../assets/audio/win.wav");

export interface AppProps
{
  level: number;
}

export interface AppState
{
  paused: boolean;
  time: number;
  data: number[];
  level: number;
  prev_flipped_card?: number;
  started: boolean;
  music: boolean;
}


const LEVELS = [
  {
    time: 30,
    cards: [
      require("../assets/img/Spielkarten/level1/blue-foam-min.png").default,
      require("../assets/img/Spielkarten/level1/inkjecta-min.png").default,
      require("../assets/img/Spielkarten/level1/karotte-min.png").default,
    ],
  },
  {
    time: 40,
    cards: [
      require("../assets/img/Spielkarten/level2/eier-min.png").default,
      require("../assets/img/Spielkarten/level2/farbkappen-min.png").default,
      require("../assets/img/Spielkarten/level2/hase-min.png").default,
      require("../assets/img/Spielkarten/level2/tatformance-min.png").default,
    ],
  },
  {
    time: 50,
    cards: [
      require("../assets/img/Spielkarten/level3/dvd-min.png").default,
      require("../assets/img/Spielkarten/level3/eier-min.png").default,
      require("../assets/img/Spielkarten/level3/grips-min.png").default,
      require("../assets/img/Spielkarten/level3/hase-min.png").default,
      require("../assets/img/Spielkarten/level3/karotte-min.png").default,
    ],
  }
]

function is_fullscreen(): boolean
{
  return !window.screenTop && !window.screenY;
}

function generate_data(level: number)
{
  return [...LEVELS[level].cards, ...LEVELS[level].cards]
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort).map(v => v.value);
}

function parse_time(time: number)
{
  const minutes = Math.max(0, Math.floor(time / 60));
  const seconds = Math.max(0, time - 60 * minutes);

  return minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");
}

const win_sound = new Audio(win.default)
const congrats_sound = new Audio(congrats.default)
const fanfare_sound = new Audio(fanfare.default)

let counter = 0;

class App extends React.Component<AppProps, AppState>
{
  state: AppState = { music: true, started: false, paused: true, level: 0, time: LEVELS[0].time, data: generate_data(0) };

  cards: React.RefObject<Card>[] = [];
  success_modal: React.RefObject<Modal> = React.createRef();
  fail_modal: React.RefObject<LevelFailedModal> = React.createRef();
  voucher_modal: React.RefObject<Modal> = React.createRef();
  voucher_modal_code: React.RefObject<HTMLSpanElement> = React.createRef();
  timer: React.RefObject<HTMLSpanElement> = React.createRef();
  fullscreen_button: React.RefObject<HTMLSpanElement> = React.createRef();
  music_button: React.RefObject<HTMLSpanElement> = React.createRef();
  audio_player: React.RefObject<HTMLAudioElement> = React.createRef();
  root: React.RefObject<HTMLDivElement> = React.createRef();
  win_svg: React.RefObject<HTMLImageElement> = React.createRef();

  second_interval_handler: NodeJS.Timer;


  componentDidMount() 
  {
    this.second_interval_handler = setInterval(this.on_second, 1000);
    //setTimeout(()=>{this.voucher_modal.current.show();}, 1000);
    // setTimeout(()=>{this.fail_modal.current.modal.current.show();}, 1000);
  }

  componentWillUnmount() 
  {
    clearInterval(this.second_interval_handler);
    this.second_interval_handler = undefined;
  }

  public render()
  {
    //"\u{26F6}"
    this.cards = [];
    return (
      <div ref={this.root} className="app-root">
        <div className="header">
          <img src={logo_ts.default} className="logo" />
          <div className="start-title extra-extra-bold">
            <div style={{marginRight:"auto"}}>
            <span>ZEIT ZUM SPIELEN</span><br />
            <span>OSTER-MEMORY</span>
            </div>
          </div>
        </div>
        <img src={stoerer.default} className={this.state.started ? "stoerer" : "stoerer stoerer-started"} />
        <audio ref={this.audio_player} autoPlay loop>
          <source src={beat_nico.default} type="audio/mp3"></source>
        </audio>
        {
          this.state.started &&
          <div key={"app"} className="app" style={{ gridTemplateColumns: `repeat(${this.state.data.length / 2},1fr)` }}>
            {
              this.state.data.map((value) =>
              {
                const ref = React.createRef<Card>();
                const result = <Card ref={ref} value={value} onFlip={this.on_flip_card} app={this} />
                this.cards.push(ref);

                return result;
              })
            }
          </div>
        }
        <div key={"timer"} className="timer" style={this.state.started ? {} : { display: "none" }}>
          <span ref={this.timer}>{parse_time(this.state.time)}</span>
        </div>
        <span key="level" className="level" style={this.state.started ? {} : { display: "none" }}>Level {this.state.level + 1}</span>
        <span ref={this.fullscreen_button} key="fullscreen" className="fullscreen" onClick={this.toggle_fullscreen}>{is_fullscreen() ? "X" : <img width={40} src={fullscreen.default} />}</span>
        <span ref={this.music_button} key="music" className="music-button" onClick={this.toggle_audio}>{this.get_audio_symbol()}</span>
        <Modal ref={this.success_modal} key="modal_finished" containerStyle={{ background: `url(${zwischenscreen.default})`, backgroundSize: "cover" }} style={{ background: "none", width: "auto", height: "auto" }}>
          <span style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", color: "white", fontSize: "min(10vw,10vh)" }}>
            <span>LEVEL</span>
            <span>COMPLETED!</span>
            <button onClick={() => { this.success_modal.current.hide(); this.next_level(); }} className="play-button" style={{ marginTop: 24, fontSize: "min(5vh,5vw)", width: "auto", padding: "max(1.5vh,1.5vw) max(3vh,3vw)", borderRadius: "max(3vh,3vw)", paddingTop: 'max(1.6vh,1.6vw)' }}>
              ZUM NÄCHSTEN LEVEL
            </button>
          </span>
        </Modal>
        <LevelFailedModal ref={this.fail_modal} key="modal_failed" level={this.state.level} on_reset={this.reset_level.bind(this)} />
        <Modal ref={this.voucher_modal} key="modal_voucher" className="final-modal">
            <span className="final-modal-title extra-bold">
              HERZLICHEN <span className="green">GLÜCKWUNSCH</span>!
            </span>
            <div className="final-modal-rabbit">
              <picture>
                <source srcSet={egg_webp.default} type="image/webp" />
                <source srcSet={egg_apng.default} type="image/apng" />
                <img src={egg.default} />
              </picture>
              <div style={{position:"absolute", width:"100%", height:"100%", justifyContent:"center", alignItems:"center"}}>
              <span key={counter++} className="final-modal-code">Ostern2022</span>
              </div>

            </div>
            <div className="final-modal-details" >
              <div className="extra-bold">
               GIB DEN RABATTCODE <span className="green">Ostern2022</span><br/> BEI DEINER NÄCHSTEN BESTELLUNG<br />EIN UND SPARE 5%
              </div>
              <img ref={this.win_svg} className="final-modal-details-logo" src={logo_ts2.default} />
            </div>
        </Modal>
        {
          (!this.state.started) && <Start on_play={this.start} />
        }
      </div>
    );
  }

  start = () =>
  {
    this.audio_player.current.volume = 0.5;
    if (this.state.music)
    {
      this.audio_player.current.play();
    }
    else
      this.audio_player.current.pause();
    this.setState({ started: true, paused: false })
  }

  /**
   * Returns a speaker or a striked-through speaker depending on whether music is playing
   * @returns 
   */
  get_audio_symbol()
  {
    return this.audio_player.current?.paused ? "\u{1F507}" : "🔊";
  }

  toggle_audio = () => 
  {
    if (!this.state.music)
      this.audio_player.current.play();
    else
      this.audio_player.current.pause();

    this.state.music = !this.state.music;

    this.music_button.current.innerHTML = this.get_audio_symbol();
  }

  toggle_fullscreen = () =>
  {
    const is_fs = is_fullscreen();

    if (is_fs)
    {
      document.exitFullscreen().then(() => this.forceUpdate());
      // this.fullscreen_button.current.innerHTML = "[]";
    }
    else
    {
      this.root.current.requestFullscreen().then(() => this.forceUpdate());
      // this.fullscreen_button.current.innerHTML = "X";

    }
  }

  on_second = () =>
  {
    if (this.state.paused)
      return;

    this.state.time -= 1;
    this.timer.current.innerHTML = parse_time(this.state.time);

    if (this.state.time <= 0)
    {
      this.state.paused = true;
      this.fail_modal.current.modal.current.show();
    }
  }

  on_flip_card = (card: Card, flipped: boolean) => 
  {
    const index = this.cards.findIndex(v => v.current == card);

    if (!flipped)
    {
      if (this.state.prev_flipped_card == index)
        this.state.prev_flipped_card = undefined;
      return;
    }

    if (this.state.prev_flipped_card == undefined)
    {
      this.state.prev_flipped_card = index;
    }
    else
    {
      const prev_card_i = this.state.prev_flipped_card;
      this.state.prev_flipped_card = undefined;

      const prev_card = this.cards[prev_card_i].current;

      if (prev_card.props.value == card.props.value)
      {
        prev_card.hide();
        card.hide();
      }
      else
      {
        prev_card.locked = true;
        card.locked = true;
        setTimeout(() =>
        {
          prev_card.locked = false;
          card.locked = false;
          prev_card.toggle();
          card.toggle();
        }, 500)
      }
    }

    if (this.cards.every(v => v.current.state.hidden))
    {
      console.log("SUCCESS")
      if (this.state.music)
      {
        if (this.state.level == (LEVELS.length-1))
        {
          win_sound.play();
        }
        else
        {
          congrats_sound.play();
        }
      }
      setTimeout(() =>
      {
        //this.next_level();
        if (this.state.level == (LEVELS.length-1))
        {
          this.voucher_modal.current.show();
          this.win_svg.current.src = this.win_svg.current.src;
          this.forceUpdate()
          
        }
        else
        {
          this.success_modal.current.show();
        }
      }, 1000);

      this.setState({ paused: true });
    }
  }

  next_level()
  {
    console.log("NEXT")
    this.state.level = this.state.level + 1;
    this.setState({ level: this.state.level, paused: false });
    this.reset_level();
  }

  reset_level()
  {
    this.setState({ time: LEVELS[this.state.level].time, data: generate_data(this.state.level), paused: false });
    for (let i = 0; i < this.cards.length; i++)
    {
      this.cards[i].current.reset();
    }
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(App);
