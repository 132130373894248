import * as React from "react";
import "./../assets/scss/Modal.scss";



export interface ModalProps
{
	hidden?:boolean;
	style?:React.CSSProperties;
	containerStyle?:React.CSSProperties;
	className?:string;
}

export interface ModalState
{
	hidden:boolean;
}

export class Modal extends React.Component<ModalProps,ModalState> {

	ref: React.Ref<HTMLDivElement> = React.createRef();
	locked = false; // true during some animations and after it has been solved

	constructor(props:ModalProps)
	{
		super(props);
		this.state = {hidden:"hidden" in props ? props.hidden : true};
	}

	public render() 
	{
		const classes = ["modal-container"];
		if(this.state.hidden)
		{
			classes.push("hidden");
		}

		return (
		<div ref={this.ref} className={classes.join(" ")} style={this.props.containerStyle}>
			<div className={`modal ${this.props.className}`} style={this.props.style}>
			{
				this.props.children
			}
			</div>
		</div>
		);
	}

	// static getDerivedStateFromProps(props, current_state) {
	// 	if (current_state.hidden !== props.hidden) {
	// 	  return {
	// 		hidden: props.hidden,
	// 	  }
	// 	}
	// 	return null
	//   }

	hide = () => 
	{
		this.setState({hidden:true});
	}

	show = () => 
	{
		this.setState({hidden:false});
	}
}
