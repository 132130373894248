import * as React from "react";

const reactLogo = require("./../assets/img/logo.svg");
const flip = require("./../assets/audio/flip.wav");
import "./../assets/scss/Card.scss";
import App from "./App";

const card = require("../assets/img/Spielkarten/karte-leer.png");



export interface CardProps
{
	onFlip:(card:Card,flipped:boolean)=>void;
	value:string;
	app:App;
	flipped:boolean;
}

export class Card extends React.Component<CardProps, {flipped:boolean,hidden:boolean}> {

	ref: React.Ref<HTMLDivElement> = React.createRef();
	locked = false; // true during some animations and after it has been solved
	flip_sound = new Audio(flip.default)

	constructor(props:CardProps)
	{
		super(props);
		this.state = {flipped:false,hidden:false};
		if(props.hidden)
		this.locked = true;
	}

	public render() 
	{
		const classes = ["card-container"];
		if (this.state.flipped)
			classes.push("flipped");
		if (this.state.hidden)
			classes.push("hidden");
		return (
		<div ref={this.ref} onClick={this.state.hidden ? undefined : this.toggle} className={classes.join(" ")}>
			<img draggable="false"  src={card.default} className={"card"}/>
			<img draggable="false"  src={this.props.value} className={"card flipside"}/>
		</div>
		);
	}

	toggle = () =>
	{
		if(this.locked)
			return;

		if(this.props.app.state.music)
			this.flip_sound.play()
		const flipped = !this.state.flipped;
		this.setState({ flipped: flipped })
		this.state.flipped = flipped;


		this.props.onFlip(this,flipped);
	}

	hide = () => 
	{
		if(this.props.app.state.music)
			this.flip_sound.play()
		this.locked = true;
		this.state.hidden = true;
		this.setState({hidden:true});
	}

	show = () => 
	{
		console.log(this);
		if(this.props.app.state.music)
			this.flip_sound.play()
		this.locked = false;
		this.state.hidden = false;
		this.setState({hidden:false});
	}

	reset()
	{
		if(this.props.app.state.music)
			this.flip_sound.play()
		this.setState({flipped:false,hidden:false});
		this.locked = false;
	}
}
