import React from "react";
import { Modal } from "./Modal";

const eggs = require("./../assets/img/eggs-broken.jpg");

export interface LevelFailedModalProps
{
	on_reset:()=>void,
	level:number
}

export class LevelFailedModal extends React.Component<LevelFailedModalProps>
{
	modal : React.RefObject<Modal> = React.createRef();

	render() {
		//background:"dimgray"
		return <Modal ref={this.modal} style={{borderRadius:2, border:"2px solid #333a", background:"#fffc", zIndex:3, width:"auto", height:"400px", minWidth:"min(400px,100vw)", padding:'32px', textAlign:"center"}} containerStyle={{backgroundImage:`url(${eggs.default})`, backgroundSize:"cover", backgroundPosition:"center"}}>
			<div style={{color:"red",width:"100%",height:"100%", display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
				<span style={{width:"100%", textAlign:"center", display:"inline-block",fontSize:48, margin:"auto", marginTop:64}}>
				<span className="extra-extra-bold">LEVEL<br/>FAILED!</span><br/>
					<span style={{fontSize:"50%", marginBottom:16}}>Die Zeit ist leider abgelaufen.</span>
				</span>
				
				<button className="play-button" style={{}} onClick={()=>{this.modal.current.hide(); this.props.on_reset();}}>Erneut versuchen</button>
				<span style={{margin:16, marginBottom:"64px"}}>Nur noch {3 - this.props.level} Level bis zum Gutschein!</span>
			</div>
		</Modal>
	}
}