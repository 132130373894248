import * as React from "react";

import "./../assets/scss/Start.scss";

const card = require("../assets/img/Spielkarten/karte-leer.png");

export class Start extends React.Component<{ on_play: () => void }>
{
  public render()
  {
    return (
      <div className="start">
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', width: "100%", alignItems: 'center' }}>
          <div className="start-cards">
            <div style={{ transform: "translate( 60px, -20px) rotate(-12deg)" }} className="card-preview"><img src={card.default} className={"card"} /></div>
            <div style={{ transform: "translate(  0px, -60px) rotate(  1deg)" }} className="card-preview"><img src={card.default} className={"card"} /></div>
            <div style={{ transform: "translate(-60px, -20px) rotate( 12deg)" }} className="card-preview"><img src={card.default} className={"card"} /></div>
          <div className="play-button-start" onClick={this.props.on_play}>JETZT SPIELEN</div>
          </div>
        </div>
      </div>
    );
  }

}
